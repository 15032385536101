import * as Plot from "@observablehq/plot";
import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import {
  exportComponentAsJPEG
} from "react-component-export-image";
import { Slider } from "../input/slider";

const d3 = require("d3")

function Line(props) {

  const initialStateValues = {
    dangerRange: 0,
    successRange: 0
  }

  const [values, setValues] = useState(initialStateValues)

  const handleInputChange = (data) => {
    setValues({ dangerRange: data[1], successRange: data[0] })
  }
  const divRef = useRef();

  let threshold = props.threshold
  let labelText = props.data.length > 0 ? props.data[0] : 0
    , fileName = props.type ? `Temperatura` : `Humedad`
    , thresholdMin = props.type ? threshold.temperatureMin : threshold.humidityMin
    , thresholdAvg = props.type ? threshold.temperatureAvg : threshold.humidityAvg
    , thresholdDanger = values.dangerRange === '0' ? thresholdAvg : values.dangerRange
    , thresholdSuccess = values.successRange === '0' ? thresholdMin : values.successRange

  useEffect(() => {
    const barChart = Plot.plot({
      width: 1000,
      height: 550,
      marginTop: 22,
      marginBottom: 80,
      marginRight: 80,
      color: {
        type: "linear"
      },
      x: {
        type: "time",
        tickRotate: 45
      },
      grid: true,
      style: {
        padding: 20,
        fontSize: 13
      },
      y: {
        grid: true,
        label: props.type ? "↑ temperatura (°)" : "↑ humedad (%)",
      },
      marks: [
        Plot.rectY([0],
          {
            y1: 0,
            y2: parseInt(thresholdSuccess),
            fill: "#2861f0",
            fillOpacity: 0.25
          }
        ),
        Plot.rectY([1],
          {
            y1: parseInt(thresholdSuccess),
            y2: parseInt(thresholdDanger),
            fill: "green",
            fillOpacity: 0.25
          }
        ),
        Plot.rectY([2],
          {
            y1: parseInt(thresholdDanger),
            y2: props.type ? 40 : 100,
            fill: "#dc4800",
            fillOpacity: 0.25
          }
        ),
        Plot.ruleY([20], { stroke: "#0003", strokeDasharray: [5] }),
        Plot.lineY(
          props.data.sort((a, b) => d3.ascending(a.ti, b.ti)),
          {
            curve: "catmull-rom",
            strokeWidth: 2,
            stroke: "gray",
            x: (d) => d3.timeFormat(d.date),
            y: props.type ? "temperature" : "humidity",
            title: (d) =>
              `${moment(d.date).format('HH:mm')} \n Temperatura: ${d.temperature.toFixed(2)}°C \n Humedad: ${d.humidity.toFixed(2)}%` // \n makes a new line
          }
        ),
        Plot.dot(
          props.data.filter((d) => d?.temperature),
          {
            r: 5,
            x: (d) => new Date(d.date),
            y: (d) => props.type ? d.temperature : d.humidity,
            fill: (d) => props.type ? d.temperature : d.humidity
          }
        )
      ]
    })
    divRef.current.append(barChart);
    return () => barChart.remove();

  }, [props, thresholdDanger, thresholdSuccess])
  return (
    <div className="card">
      <button
        style={{ display: "none" }}
        onClick={props.printChart ? exportComponentAsJPEG(divRef, { fileName }) : null}
      />
      <div className="left-column background1-left-column">
        {
          thresholdMin > 0 || thresholdAvg > 0
            ? <Slider type={props.type}
              thresholdMin={thresholdMin}
              thresholdAvg={thresholdAvg}
              dataCallback={handleInputChange} orientation="vertical" defaultValue={[25, 75]}
            />
            : null
        }
      </div>
      <div className="right-column">
        <h4>{props.type
          ? `Última Temperatura Registrada: ${labelText.temperature}° ${moment(labelText.date).format("YYYY-MM-DD HH:mm")}`
          : `Última Humedad Registrada: ${labelText.humidity}% ${moment(labelText.date).format("YYYY-MM-DD HH:mm")}`
        }</h4>
        <div ref={divRef} />
      </div>
    </div >
  )
}

export default Line