import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDix9FqUGWs8SGr1vRZfZR1l7fnvlaDjzw",
  authDomain: "agropatterns20.firebaseapp.com",
  databaseURL: "https://agropatterns20-default-rtdb.firebaseio.com",
  projectId: "agropatterns20",
  appId: "1:1066230381770:web:886fcde0f82a2a51484833",
  uiConfig: {
    signInOptions: ["google.com", "password", "phone", "anonymous"]
  }
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);