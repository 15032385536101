import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Dashboard from './components/dashboard'
import Login from "./components/login"
import { AuthProvider } from "./db/apiMongoDB"

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/:authToken/:groupId' element={<Dashboard />} />
          <Route path='/' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
