import React, { useEffect, useState, useRef } from 'react'
import { styled } from "@stitches/react";
import * as SliderPrimitive from "@radix-ui/react-slider";

export function Slider(props) {
  const [value, setValue] = useState([props.thresholdMin, props.thresholdAvg]);
  const minThumbRef = useRef();

  const maxThumbRef = useRef();

  useEffect(() => {
    props.dataCallback(value)
    if (!minThumbRef.current || !maxThumbRef.current) return;
  }, [value]);

  return (
      <StyledSlider
        value={value}
        onValueChange={(val) => setValue(val)}
        min={0.1}
        max={props.type ? 40 : 100}
        orientation="vertical"
      >
        <StyledTrack>
          <StyledRange />
        </StyledTrack>
        <StyledThumb ref={minThumbRef} />
        <StyledThumb ref={maxThumbRef} />
      </StyledSlider>
  );
}

const StyledSlider = styled(SliderPrimitive.Root, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  "&[data-orientation=horizontal]": { height: 16 },
  "&[data-orientation=vertical]": {
    flexDirection: "column",
    width: 16,
    height: "100%"
  }
});

const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: "gainsboro",
  position: "relative",
  flexGrow: 1,
  "&[data-orientation=horizontal]": { height: 2 },
  "&[data-orientation=vertical]": { width: 5 }
});

const StyledRange = styled(SliderPrimitive.Range, {
  position: "absolute",
  backgroundColor: "dodgerblue",
  flexGrow: 1,
  borderRadius: "9999px",
  "&[data-orientation=horizontal]": { height: "100%" },
  "&[data-orientation=vertical]": { width: "100%" }
});

const StyledThumb = styled(SliderPrimitive.Thumb, {
  display: "block",
  width: 20,
  height: 20,
  backgroundColor: "dodgerblue",
  border: "1px solid lightgray",
  borderRadius: "20px",
});

