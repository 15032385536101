import sha256 from 'sha256'
import { createContext, useContext, useEffect, useState } from "react";
import moment from 'moment'

export const authContext = createContext()

export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error('There is not auth provider')
  return context
}

export const saveApplicationRoute = async (currentDate, currentRoute, { user }) => {
  let totalQrReq = currentRoute.split("}").length - 1
    , str = ""
    , year = moment(new Date()).format('YYYY')
    , week = moment(new Date()).format('W')
  if (totalQrReq > 1) {
    let strObj = currentRoute.split("}").join("},")
    let strClear = strObj.substr(0, strObj.length - 1);
    str = `[${strClear}]`
  } else str = `[${currentRoute}]`
  let ArrRoute = JSON.parse(str)
    , formBody = []
  ArrRoute.forEach(({ location }, i) => {
    formBody.push({ location, timestamp: currentDate[i] })
  })
  try {
    const response = await fetch('https://iot.agropatterns.com/server/api/countermeasures', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data: formBody, userId: user._id, groupId: "qZFKdErNMkXACrvYB", year, week}),
      mode: 'cors'
    });
    const res = await response.json();
    return res
  }
  catch (e) {
    console.log(e)
  }
}

export function AuthProvider({ children }) {

  const [user, setUser] = useState(null)
  const login = async (email, password) => {
    const passhex = sha256(password);
    let details = {
      email,
      'password': passhex
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    try {
      const response = await fetch('https://iot.agropatterns.com/server/api/login/productionTest', {
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
        mode: 'cors'
      });
      const data = await response.json();
      window.localStorage.setItem(
        'loggedNoteAppUser', JSON.stringify(data)
      )
      setUser(data)
      return data
    }
    catch (e) {
      console.log(e)
    }
  }

  const logout = () => {
    window.localStorage.setItem(
      'loggedNoteAppUser', JSON.stringify("")
    )
  }

  useEffect(() => {
    const loggedUserJson = window.localStorage.getItem('loggedNoteAppUser')
    if (loggedUserJson) {
      const user = JSON.parse(loggedUserJson)
      setUser(user)
    }
  }, [])

  return (
    <authContext.Provider value={{ login, user, logout }}>
      {children}
    </authContext.Provider>
  )
}